'use client'; // Error components must be Client components

import { Button } from '@/components/core/Button/Button';
import { ObjectValues } from '@/utils/types';
import { useTranslation } from '@app/i18n/client';
import Link from 'next/link';
import { css } from 'styled-system/css';
import { Container } from 'styled-system/jsx';
import { heading } from 'styled-system/recipes';

const ERROR_DIGESTS_TYPES = {
	NO_PERMISSIONS: 'NO_PERMISSIONS',
	UNKNOWN: 'UNKNOWN',
} as const;

const errorDigestToType: Record<string, ObjectValues<typeof ERROR_DIGESTS_TYPES> | undefined> = {
	'4052242416': ERROR_DIGESTS_TYPES.NO_PERMISSIONS,
};

interface IErrorProps {
	error: Error & { digest: string };
	reset: () => void;
}

export default function Error({ error, reset }: IErrorProps) {
	const { t } = useTranslation();
	const errorType = errorDigestToType[error.digest] ?? ERROR_DIGESTS_TYPES.UNKNOWN;

	const pageHeading = heading();

	return (
		<Container>
			{errorType === ERROR_DIGESTS_TYPES.NO_PERMISSIONS && (
				<>
					<h2 className={pageHeading.title}>Something went wrong!</h2>
					<p className={css({ mb: 12, fontSize: 'xl', color: 'gray.500', maxW: '3xl' })}>
						Looks like you have no permissions right now. Please contact someone from{' '}
						<Link
							className={css({ textDecoration: 'underline' })}
							href="https://slack.com/app_redirect?channel=C04HY6XCLVB"
							target="_blank"
						>
							#js-revisor
						</Link>{' '}
						for help.
					</p>
				</>
			)}

			{errorType === ERROR_DIGESTS_TYPES.UNKNOWN && (
				<>
					<h2 className={pageHeading.title}>Something went wrong!</h2>
					<p className={css({ mb: 12, fontSize: 'xl', color: 'gray.500', maxW: '3xl' })}>
						An unknown error happened. Please contact someone from{' '}
						<Link
							className={css({ textDecoration: 'underline' })}
							href="https://slack.com/app_redirect?channel=C04HY6XCLVB"
							target="_blank"
						>
							#js-revisor
						</Link>{' '}
						for help. [{error.digest}]
					</p>
				</>
			)}

			<Button onClick={reset} size="lg">
				{t('global.retry')}
			</Button>
		</Container>
	);
}
