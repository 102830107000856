'use client';

import i18next from 'i18next';
import { initReactI18next, useTranslation as useTranslationOrg } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

import { defaultNS, fallbackLng, getOptions } from './settings';

i18next
	.use(initReactI18next)
	.use(
		resourcesToBackend(
			(language: string, namespace: string) => import(`../../public/locales/${language}/${namespace}.json`)
		)
	)
	.init(getOptions());

export function useTranslation(lng: string = fallbackLng, ns: string | Array<string> = defaultNS) {
	if (i18next.resolvedLanguage !== lng) i18next.changeLanguage(lng);

	return useTranslationOrg(ns);
}
