export const fallbackLng = 'en';
export const languages = [fallbackLng];
export const defaultNS = 'common';
export const i18nCookieName = 'i18next';

export function getOptions(lng: string = fallbackLng, ns: string | Array<string> = defaultNS) {
	return {
		supportedLngs: languages,
		fallbackLng,
		lng,
		fallbackNS: defaultNS,
		defaultNS,
		ns,
	};
}
